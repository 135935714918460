import React from 'react'

import { Router } from "@reach/router"

import PageArticlesPreview from 'components/pageDataPreview/PageArticlesPreview'

const ArticlePreview = () => (
  <Router basepath={`/en/article_preview`}>
    <PageArticlesPreview path={'/:slug'} />
  </Router>
)

export default ArticlePreview
